// ** Core JWT Import
//import useJwt from '@src/@core/auth/jwt/useJwt'
// ** JWT Service Import
import JwtService from './jwtService'

// ** Export Service as useJwt
function useJwt(jwtOverrideConfig) {
  const jwt = new JwtService(jwtOverrideConfig)

  return {
    jwt
  }
}

// const { useJwt } = useJwt({})
// const { jwt } = useJwt({})
const Jwt = () => {
    const { jwt } = useJwt({})

    return (
        jwt
    )
}

export default Jwt()
